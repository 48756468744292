.project-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 0 30px 20px 30px;
}

.project-card {
  border-radius: 10px;
  padding: 10px;
  width: 300px;
  height: 200px;
  position: relative;
}

.dark main .project-card {
  border: 2px solid var(--color-400);
  color: var(--color-300);
  background-color: var(--color-800);
  box-shadow: 0 0 7px 0 var(--color-800);
}

.light main .project-card {
  border: 2px solid var(--color-600);
  color: var(--color-800);
  background-color: var(--color-300);
  box-shadow: 0 0 3px 0 var(--color-600);
}

.projects-heading {
  margin-left: 30px;
  margin-bottom: 20px;
}

.dark main .projects-heading {
  color: var(--color-200);
}

.light main .projects-heading {
  color: var(--color-700);
}

.project-heading {
  margin-left: 10px;
  margin-bottom: 10px;
}

.dark main .project-heading {
  color: var(--color-300);
}

.light main .project-heading {
  color: var(--color-600);
}

.project-links {
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;

  width: 100%;
}

.project-links a {
  flex: 1;
  text-align: center;
  padding: 5px;
  color: inherit;
  text-decoration: none;
}

.dark main .project-links a:hover {
  background-color: var(--color-900);
}

.light main .project-links a:hover {
  background-color: var(--color-400);
}

.dark main .project-links a {
  border-top: 1px solid var(--color-400);
}

.dark main .project-links a:not(:last-child) {
  border-right: 1px solid var(--color-400);
}

.light main .project-links a {
  border-top: 2px solid var(--color-400);
}

.light main .project-links a:not(:last-child) {
  border-right: 2px solid var(--color-400);
}

.project-links a:first-child {
  border-bottom-left-radius: 10px;
}

.project-links a:last-child {
  border-bottom-right-radius: 10px;
}

.project-description a {
  color: inherit;
}
