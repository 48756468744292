header .nav {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
}

header .nav > * {
  padding: 10px;
  transform-origin: top left;
  transition: transform 100ms ease-in-out;
  cursor: pointer;
}

/* options of nav bar */
.dark header .nav > * {
  background-color: var(--color-900);
  border-bottom: 1px solid var(--color-400);
}

.light header .nav > * {
  background-color: var(--color-400);
  border-bottom: 3px solid var(--color-900);
}

.dark header .nav {
  color: var(--color-400);
  background-color: var(--color-900);
}

.light header .nav {
  color: var(--color-900);
  background-color: var(--color-400);
}

header .nav > *:hover {
  transform: translateY(3px);
}

header .nav .active {
  cursor: default;
}

.dark header .nav .active {
  color: var(--color-100);
  border-bottom: 1px solid var(--color-100);
}

.light header .nav .active {
  color: var(--color-100);
  border-bottom: 3px solid var(--color-300);
}

header .nav a {
  text-decoration: none;
  color: inherit;
}

header .nav .github-link {
  display: flex;
  gap: 5px;
}

header .nav .github-link img {
  width: 20px;
  height: 20px;
  align-self: center;
}

.dark header .nav .github-link img {
  filter: invert(73%) sepia(9%) saturate(686%) hue-rotate(175deg)
    brightness(88%) contrast(88%);
}

.light header .nav .github-link img {
  filter: invert(8%) sepia(11%) saturate(3617%) hue-rotate(184deg)
    brightness(92%) contrast(97%);
}
