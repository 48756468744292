.about-me {
  max-width: 600px;
  margin: auto;
  padding: 0 20px 20px 20px;
}

.about-me-p {
  font-size: 1.3rem;
}

.about-me-heading {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.dark .about-me-p {
  color: var(--color-300);
}

.light .about-me-p {
  color: var(--color-900);
}

.dark .about-me-heading {
  color: var(--color-200);
}

.light .about-me-heading {
  color: var(--color-700);
}
