.not-found {
  max-width: 600px;
  margin: auto;
  padding: 0 20px 20px 20px;
  font-size: 2rem;
}

.dark .not-found {
  color: var(--color-300);
}

.light .not-found {
  color: var(--color-800);
}
